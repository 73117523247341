.container {
  width: 612px;
}

.item {
  margin: 24px 0;
}

.roundCheckbox {
  span.ant-checkbox-inner {
    background-color: hotpink;
    border-radius: 50%;
  }
}

.errorMessage {
  color: #f43154;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 10px;
}
