body {
    margin: 0;
    background: #f7f8fa;
    box-sizing: border-box;
    font-family: 'MullerRegular';
    line-height: inherit;
}

* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'MullerRegular';
    src: url('./fonts/Muller/MullerRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'MullerMedium';
    src: url('./fonts/Muller/MullerMedium.ttf') format('truetype');
}

@font-face {
    font-family: 'MullerBold';
    src: url('./fonts/Muller/MullerBold.ttf') format('truetype');
}

@font-face {
    font-family: 'MullerLight';
    src: url('./fonts/Muller/MullerLight.ttf') format('truetype');
}

@font-face {
    font-family: 'LatoRegular';
    src: url('./fonts/Lato2OFL/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'LatoMedium';
    src: url('./fonts/Lato2OFL/Lato-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'LatoLight';
    src: url('./fonts/Lato2OFL/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'LatoBold';
    src: url('./fonts/Lato2OFL/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsNormal';
    src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

a:hover {
    color: inherit;
}

.ant-drawer-footer {
    border: none;
    padding: 40px;
}

.ant-drawer-header {
    border: none;
}

.no-scroll {
  overflow-y: hidden;
}