.whiteBlock {
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 28px;
}

.input {
  margin-bottom: 32px;
}

.submit {
  width: 126px;
}

.btnsHolder {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
}

.connectBtn {
  width: 200px;
}

.apiLabel {
  font-size: 16px;
  font-family: "MullerRegular", sans-serif;
  color: #70717c;
  margin-bottom: 8px;
}

.uploadHolder {
  width: 100%;
  border: 1px dashed #888c97;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dragText {
  color: #70717c;
}

.uploadText {
  color: #888c97;
  font-size: 12px;
  margin-top: 4px;
  text-align: center;
}

.uploadBtn {
  margin-top: 24px;
  height: 40px;
  width: 177px;
}

.attachedContainer {
  background: #eceef2;
  border-radius: 8px;
  height: 28px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.fileWeight {
  font-size: 16px;
  color: #888c97;
  font-family: "MullerRegular", sans-serif;
  margin-left: auto;
}

.close {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
  padding: 0;
}

.closeIcon {
  width: 20px;
}

.filesInput {
  display: none;
}

.name {
  max-width: 329px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  position: relative;
}

.loadingHolder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: rgba(247, 248, 250, 0.8);
}

.labelInput {
  text-transform: capitalize;
}

.titleHolder {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.icon {
  position: relative;
  margin-right: 10px;
  bottom: 2px;
}

.link {
  margin-top: 10px;
  display: block;
  margin-left: 34px;
  font-size: 16px;
  text-decoration: underline;
  color: var(--purple-8);
}
