.container {
  width: 100%;
  display: flex;
}

.bubble {
  padding: 16px;
  border-radius: 12px;
  bottom: 20px;
  left: -600px;
  z-index: 2;
  position: fixed;
  font-size: 16px;
  font-family: MullerRegular, serif;
  line-height: 24px;
  transition: left 0.5s ease-in;
}

.error {
  padding-left: 50px;
  padding-left: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &.light {
    background: url(./assets/error.png), #f7f8fa;
    background-repeat: no-repeat;
    background-position: 19px 14px;
    box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
    color: #131416;
  }

  &.dark {
    background: url(./assets/error.png), #70717c;
    background-repeat: no-repeat;
    background-position: 19px 14px;
    box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
    color: #ffffff;
  }
}

.success {
  padding-left: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &.light {
    background: url(./assets/success.png), #f7f8fa;
    background-repeat: no-repeat;
    background-position: 19px 14px;
    box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
    color: #fbfbfd;
  }

  &.dark {
    background: url(./assets/success.png), #4e5169;
    background-repeat: no-repeat;
    background-position: 19px 14px;
    box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
    color: #ffffff;
  }
}

.btn {
  background: none;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.btnDark {
  color: #eceef2;
}

.btnLight {
  color: #888c97;
}

.opened {
  left: 20px;
}
