.blockTitle {
  font-size: 20px;
  font-family: 'MullerBold', serif;
  margin: 0 0 26px;
}

.title {
  font-size: 20px;
  font-family: 'MullerBold', serif;
}
