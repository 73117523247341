.container {
  display: grid;
}

.head {
  display: grid;
  border-bottom: 1px solid #e0e0e0;
  color: #888C97;
  font-family: 'MullerRegular', serif;
  font-size: 14px;
  background: #ffffff;
  height: 42px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  justify-items: center;
}

.body {
  display: grid;
  background: #ffffff;
  font-family: 'MullerRegular', serif;
  font-size: 16px;
  grid-auto-rows: minmax(52px, auto);
  align-items: center;
  justify-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  & div{
    display: flex;
    align-items: flex-end;
  }
}
