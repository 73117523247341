.container {
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.submit {
  background: #7C55EB;
  color: #FFFFFF;
  font-size: 16px;
  font-family: 'MullerRegular', serif;
  &:hover:not(:disabled) {
    background: #6335E4;
    cursor: pointer;
  }
  &:active:not(:disabled) {
    background: #5D31DA;
  }
  &:disabled {
    opacity: 0.4;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.cancel {
  background: #ECEEF2;
  color: #131416;
  font-family: 'MullerRegular', serif;
  font-size: 16px;
  &:hover:not(:disabled) {
    background: #DFE2E9;
    cursor: pointer;
  }
  &:active:not(:disabled) {
    background: #DFE2E9;
  }
  &:disabled {
    opacity: 0.4;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.delete {
  background: #ECEEF2;
  color: #F43154;
  font-family: 'MullerRegular', serif;
  font-size: 16px;
  &:hover:not(:disabled) {
    background: #DFE2E9;
    cursor: pointer;
  }
  &:active:not(:disabled) {
    background: #DFE2E9;
  }
  &:disabled {
    opacity: 0.4;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.flat {
  background: transparent;
  border: 1px solid #888C97;
  border-radius: 8px;
  font-size: 16px;
  color: #70717C;
  font-family: 'MullerRegular', serif;
  white-space: nowrap;
  &:hover:not(:disabled) {
    cursor: pointer;
  }
}

.icon {
  margin-right: 15px;
  margin-top: 3px;
}

.iconBtn {
  height: 40px;
  padding: 0 18px;
}
