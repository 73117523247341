.btn {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
}

.title {
  font-family: 'MullerMedium', serif;
  font-size: 16px;
}

.container {
  width: 100%;
  min-height: 88px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 0 32px;
}

.notFilled {
  width: 26px;
  height: 26px;
  background: url(./assets/notFilled.svg) no-repeat center;
  background-size: contain;
  margin-right: 13px;
}

.error {
  width: 26px;
  height: 26px;
  background: url(./assets/error.svg) no-repeat center;
  background-size: contain;
  margin-right: 13px;
}

.valid {
  width: 26px;
  height: 26px;
  background: url(./assets/valid.svg) no-repeat center;
  background-size: contain;
  margin-right: 13px;
}

.content {
  background: #FFFFFF;
  padding: 0 32px 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
