.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 914px;
  padding: 20px;
  min-width: 914px;
  margin: 0 auto;
}

.tableSize {
  grid-template-columns: repeat(1, 20% 50% 30%);
  max-height: 700px;
  overflow: auto;
  margin-bottom: -35px;
  padding-right: 12px;
  position: absolute;
  width: 100%;
  & div {
    width: 100%;
    padding: 0 16px;
  }
  & div:nth-child(3n+3) {
    justify-content: flex-end;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border:1px solid #E1E1E4;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #C9CACF;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

}

.input {
  padding: 12px;
  margin-left: 30px;
  width: 264px;
}

.down {
  position: absolute;
  bottom: -36px;
  width: calc(100% - 12px);
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  margin-bottom: 12px;
  position: relative;
  background: #FFFFFF;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px ;
  border-collapse: collapse;
  max-height: calc(100vh - 178px);
  display: block;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border:1px solid #E1E1E4;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #C9CACF;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  th {
    border-bottom: 1px solid #e0e0e0;
  }
}

.tbody {
  display: inline-table;
  width: 100%;
}

.thead {
  display: inline-table;
  width: 100%;
  position: sticky;
  top: 0;
  background: #fff;
}

.title {
  font-size: 20px;
  font-family: 'MullerBold', serif;
}

.controls {
  display: flex;
  margin-bottom: 12px;
}

.search {
  width: 280px;
  margin-left: 20px;
}

.positive {
  color: #22B465;
}

.cell {
  height: 48px;
}

.th {
  position: sticky;
  top: 0;
}

.cellInner {
  padding-left: 16px;
  text-align: left;
}

.cellInnerLast {
  padding-right: 16px;
  text-align: right;
}

.cellInnerCenter {
  text-align: center;
}

.headerText {
  color: #888C97;
  font-size: 14px;
  font-family: MullerRegular, sans-serif;
  font-weight: normal!important;
  margin-right: 6px;
}

.conversionHolder {
  display: flex;
  align-items: flex-end;
}

.emptyPlaceholder {
  width: 100%;
  height: 200px;
  color: #70717C;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.totalBlock {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px;
  font-family: MullerMedium, serif;
  font-size: 16px;
  margin-top: 20px;
}

.finInfoBtn {
  display: flex;
  height: 40px;
}

.currInfoBtn {
  display: flex;
  height: 40px;
  width: 112px;
}

.btnsHolder {
  display: flex;
  justify-content: space-between;
  background: #ffff;
  padding: 16px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
