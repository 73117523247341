.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1314px;
  padding: 20px;
  min-width: 914px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  color: #131416;
  font-family: "MullerBold", serif;
  margin-top: 0;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sourcesList {
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  gap: 20px;
}

.connectContainer {
  width: 100%;
  max-width: 674px;
  margin: 0 auto;
  position: relative;
}

.connectTitle {
  margin-top: 10px;
  margin-bottom: 36px;
  align-items: center;
  display: flex;
}

.input {
  margin-bottom: 32px;
}

.submit {
  width: 156px;
  height: 40px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(247, 248, 250, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.cardHolder {
  width: 290px;
}

.apiLabel {
  font-size: 16px;
  font-family: 'MullerRegular', sans-serif;
  color: #70717C;
  margin-bottom: 8px;
}

.uploadHolder {
  margin-bottom: 30px;
}

.uploaded {
  background: #FFFFFF;
  border: 1px solid #ECEEF2;
  border-radius: 8px;
  padding: 10px 20px 10px 10px;
  display: inline-flex;
  align-items: center;
}

.btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.textUploaded {
  color: #1C1D21;
  margin-left: 10px;
  margin-right: 8px;
}

.backIcon {
  margin-right: 18px;
  position: relative;
  top: 2px;
}

.text {
  color: #70717C;
  margin-top: 60px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.link {
  color: #5F2EEA;
  font-size: 16px;
  margin-bottom: 24px;
  display: inline-block;
}

.icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.accountName {
  display: flex;
}