.holder {
  background: #FFFFFF;
  border: 1px solid #E1E1E4;
  border-radius: 8px;
  height: 60px;
  padding: 8px 18px;
  position: relative;
}

.input {
  border: none;
  margin-right: 40px;
  font-size: 16px;
  font-family: "MullerRegular", serif;
  outline: none;
  color: #131416;
  top: 22px;
  position: relative;
  width: 100%;
}

.errorInput {
  border: none;
  margin-right: 40px;
  font-size: 16px;
  font-family: "MullerRegular", serif;
  outline: none;
  color: #F43154;
  top: 22px;
  position: relative;
  width: 100%;
}

.error {
  border: 1px solid #F43154;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  position: absolute;
  animation: fadeInDown 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.label{
  position: absolute;
  top: 22px;
  color: #70717C;
  font-size: 16px;
  transition: 0.5s ease;
}

input:focus + .label,
input:not([value=""]) + .label {
  font-size: 12px;
  color: #70717C;
  top: 8px;
}

.inputHolder {
  margin-bottom: 32px;
  position: relative;
}
