.tableSize {
  grid-template-columns: 14% 16% 16% 16% 13% 16% 9%;
}

.emptyBody {
  grid-template-columns: repeat(1, 1fr);
  height: 120px;
}

.noDataText {
  color: #70717c;
  font-size: 14px;
}

.endFormat {
  text-align: right;
  margin-left: 42px;
}

.endFormatBody {
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 13px;
  width: 100%;
}

.startFormat {
  width: 100%;
  margin-left: 42px;
}

.first {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 42px;
  width: 100%;
}

.titleTooltip {
  margin-right: 5px;
}

.btn {
  width: 153px;
}

.btnSendRequest {
  width: 152px;
}

.btnHolder {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.available {
  font-family: "MullerBold", serif;
  font-size: 20px;
  line-height: 20px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  width: fit-content;
  margin-bottom: 8px;
  background: none;
  &:hover {
    background: #eceef2;
  }
}

.activeAvailable {
  background: #eceef2;
}

.list {
  background: #ffffff;
  border-radius: 8px;
  padding: 4px;
  position: absolute;
  width: calc(100% - 10px);
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  z-index: 3;
}

.dropDown {
  position: relative;
  width: fit-content;
}

.active {
  background: #eceef2;
  color: #131416 !important;
}

.listItem {
  color: #70717c;
  font-size: 16px;
  font-family: "MullerRegular", serif;
  height: 36px;
  cursor: pointer;
  padding-left: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  text-decoration: none;
  &:hover {
    background: #eceef2;
    color: #131416;
  }
}

.close {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
}

.opened {
  transform: rotate(180deg);
}

.downText {
  margin-right: 9px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.createRequestHolder {
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1600px;
  padding: 20px;
  min-width: 914px;
}

.regularText {
  color: #70717c;
  font-family: "MullerRegular", serif;
  font-size: 14px;
}

.availableText {
  margin-bottom: 12px;
}

.amount {
  color: #14142b;
  font-size: 20px;
  font-family: "MullerBold", serif;
}

.btnCreateRequest {
  width: 280px;
  font-size: 20px;
}

.availableContent {
  display: flex;
  flex-direction: column;
}

.editRequestHolder {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 23px 16px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
}

.new {
  border: 1px solid #f57b51;
}

.pending {
  border: 1px solid #bcbdc2;
}

.rejected {
  border: 1px solid #f43154;
}

.signed {
  border: 1px solid #9fa5b9;
}

.funded {
  border: 1px solid #b099f2;
}

.link {
  font-size: 14px;
  color: #14142b;
  font-family: "MullerBold", serif;
  margin-left: 8px;
}

.editBtn {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 25px;
}

.editIcon {
  margin-left: 10px;
}

.editBtnsHolder {
  display: flex;
  margin-left: auto;
}

.newText {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createBtn {
  width: 154px;
}

.breakDown {
  color: #5f2eea;
  font-size: 14px;
  font-family: "MullerRegular", serif;
  margin-top: 32px;
  cursor: pointer;
  border: none;
  background: none;
  margin-bottom: 20px;
}

.backDropFunds {
  background: #f7f8fa;
  padding: 27px 20px 20px 20px;
}

.logoHolder {
  width: 20%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.companyName {
  color: #131416;
  font-size: 14px;
  font-family: "MullerRegular", serif;
  margin-left: 4px;
}

.modal {
  width: 820px;
  max-width: 820px;
  background: #ffffff;
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 40px;
  box-sizing: border-box;
}

.advanceTitle {
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.1px;
  font-family: "MullerMedium", serif;
  margin: 0 0 12px;
  margin: 0 0 12px;
}

.priceInput {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #14142b;
  border-radius: 8px 8px 0px 0px;
  width: 221px;
  height: 60px;
  font-size: 32px;
  position: relative;
  padding-left: 40px;
  font-family: "MullerMedium", serif;
  font-weight: normal;
  padding-top: 6px;
  box-sizing: border-box;
  border: none;
}

.priceInputSmall {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #c9cacf;
  border-radius: 8px 8px 0px 0px;
  width: 145px;
  height: 40px;
  font-size: 20px;
  position: relative;
  padding-left: 23px;
  font-family: "MullerMedium", serif;
  font-weight: normal;
  padding-top: 3px;
  box-sizing: border-box;
  border: none;
  outline: none;
  &:focus {
    box-shadow: inset 0px -1px 0px #14142b;
  }
}

.label {
  font-size: 32px;
  left: 11px;
  position: absolute;
  font-family: "MullerRegular", serif;
  z-index: 2;
  top: 17px;
}

.mainInputHolder {
  position: relative;
  margin: 2px auto 0;
  width: 221px;
  box-sizing: border-box;
}

.smallInputHolder {
  position: relative;
  margin: 12px auto 24px;
  width: 30%;
  max-width: 145px;
  box-sizing: border-box;
  top: 10px;
  left: 10px;
}

.totalInfo {
  font-size: 14px;
  margin-top: 12px;
  letter-spacing: 1px;
  color: #a3a7af;
  font-family: "MullerRegular", serif;
}

.companyDetail {
  display: flex;
  align-items: center;
}

.labelSmall {
  top: 11px;
  left: 7px;
  z-index: 2;
  position: absolute;
  font-size: 20px;
  font-family: "MullerRegular", serif;
}

.sendModalTitle {
  color: #2e2c38;
  font-family: "MullerRegular", serif;
  font-size: 24px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}

.contentHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 238px;
  background: #f7f8fa;
  border-radius: 8px;
  padding-top: 24px;
}

.modalTextTitle {
  color: #2e2c38;
  font-family: "MullerMedium", serif;
  font-size: 24px;
  margin-bottom: 0px;
}

.successText {
  color: #22b465;
}

.subTitle {
  color: #888c97;
  font-size: 14px;
  margin-top: 20px;
  font-family: "MullerRegular", serif;
  text-align: center;
}

.modalDescription {
  color: #888c97;
  font-size: 14px;
  font-family: "MullerRegular", serif;
  margin-top: 20px;
}

.staticDown {
  padding-right: 12px;
  position: relative;
  top: -1px;
  transform: rotate(180deg);
}

.activeDown {
  padding-left: 12px;
  position: relative;
  top: -1px;
}

.percentsSlider {
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  color: #a3a7af;
  font-family: "MullerRegular", serif;
  text-align-last: right;
  position: relative;
  left: 8px;
  top: 4px;
}

.emptyTitle {
  font-size: 20px;
  font-family: MullerBold, serif;
  margin-bottom: 12px;
}

.emptySubtitle {
  font-size: 14px;
}

.rcSliderTrack {
  position: absolute;
  height: 6px;
  background-color: #c9cacf;
  border-radius: 8px;
}

.track {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.btnRequestHolder {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}

.cancelBtn {
  width: 152px;
  font-family: MullerMedium, serif;
  margin-right: 20px;
}

.columnRow {
  background: #ffffff;
  font-family: MullerRegular, sans-serif !important;
  border: none;
}

.head {
  color: #888c97 !important;
  font-family: MullerRegular, serif !important;
  font-size: 14px !important;
  padding: 0 16px !important;
  height: 42px !important;
  &:first-child {
    border-radius: 8px 0px 0px 0px;
  }
  &:last-child {
    border-radius: 0px 8px 0px 0px;
  }
}

.cell {
  font-family: MullerRegular, serif !important;
  font-size: 16px;
  height: 52px;
  padding: 0px 0px 0px 21px !important;
  border: none !important;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.companyInfo {
  display: flex;
}

.paginationItem {
  background: #ffffff !important;
  border-radius: 4px !important;
}

.text {
  color: #888c97;
  font-family: "MullerRegular", serif;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.rowsText {
  margin-right: 8px;
}

.topTable {
  display: flex;
  background: #ffffff;
  padding: 26px 16px 16px;
  border-radius: 8px 8px 0px 0px;
  flex-direction: column;
}

.totalBlock {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 40px;
  display: flex;
}

.totalText {
  font-family: "MullerRegular", serif;
  font-size: 16px;
}

.totalAmount {
  font-family: "MullerRegular", serif;
  font-size: 16px;
}

.bottomText {
  margin-left: 60px;
}

.modal {
  width: 820px;
  max-width: 820px;
  background: #ffffff;
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 40px;
  box-sizing: border-box;
}

.title {
  font-family: "MullerRegular", serif;
  font-size: 24px;
  color: #2e2c38;
  margin: 0;
}

.disclaimer {
  font-family: "MullerRegular", serif;
  font-size: 14px;
  color: #70717c;
  margin: 20px 0;
}

.greyHolder {
  background: #f7f8fa;
  padding: 20px;
  border-radius: 8px;
}

.titleTable {
  color: #131416;
  font-family: "MullerBold", serif;
  font-weight: 700;
  font-size: 20px;
  margin: 0px 0px 30px;
}

.titleHeader {
  color: #888c97;
  font-size: 14px;
  margin-right: 6px;
}

.divider {
  background: #dddee0;
  height: 1px;
  margin-top: 13px;
}

.cellText {
  color: #131416;
  font-size: 16px;
  padding-right: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellTextGet {
  color: #131416;
  font-size: 16px;
  font-family: "MullerBold", serif;
  padding-right: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img {
  width: 20px;
  margin-right: 6px;
}

.container {
  display: flex;
  margin-bottom: 22px;
  padding: 6px 16px;
}

.item {
  color: #888c97;
  font-size: 14px;
  margin-right: 40px;
  font-family: "MullerRegular", serif;
  text-transform: uppercase;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0 0 6px;
}

.active {
  color: #000000;
  border-bottom: 1px solid;
  transition: 0.5s ease;
}

.typeBtns {
  margin-right: 24px;
}

.btnsHolder {
  display: inline-flex;
}

.currencyBtns {
  margin-left: auto;
  width: 112px;
}

.longRow {
  width: 100%;
  text-align: right;
  white-space: nowrap;
}
