.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 20px;
  width: 914px;
  min-width: 914px;;
  border-radius: 8px 8px 0px 0px;
  margin: 16px auto 0;
}

.tableSize {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
}

.header {
  height: 32px;
  padding-left: 16px;
  border-bottom: 1px solid #eceef2;
  font-size: 14px;
  color: #888C97;
}


.linked {
  border-bottom: 1px solid;
  cursor: pointer;
  text-decoration: none;
  color: #5F2EEA;
  line-height: 13px;
}

.secondColumn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 32px;
}

.firstColumn {
  width: 40px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding-left: 13px;
}

.btn {
  background: none;
  border: none;
  margin-left: auto;
  padding-right: 20px;
  cursor: pointer;
  display: none;
  color: #888C97;
}

.emptyContainer {
  height: 120px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.emptyText {
  color: #70717C;
  font-size: 14px;
}

.skeleton {
  background: #ECEEF2;
  border-radius: 4px;
  height: 16px;
  width: 100%;
  max-width: 471px;
}

.title {
  font-size: 20px;
  color: #131416;
  font-family: 'MullerBold';
  margin-top: 0;
}

.filtersHolder {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
}

.row {
  display: flex;
  height: 56px;
  &:hover {
    background: var(--gray-4);
    cursor: pointer;
    & .btn {
      display: block;
    }
  }
}

.aoIcon {
  padding: 1px;
  background: var(--purple-2);
  border-radius: 4px;
  color: var(--purple-8);
}

.reportIcon {
  background: #fff5f2;
  padding: 4px;
  border-radius: 4px;
  color: #F57B51;
}

.loaderItem {
  min-width: 80px;
}

.loaderRow {
  height: 56px;
  align-items: center!important;
  margin-right: auto;
}