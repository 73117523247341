.title {
  font-family: 'MullerBold', serif;
  font-size: 35px;
  margin-top: 80px;
  margin-bottom: 32px;
}

.titleHighlight {
  color: #7C55EB;
}

.container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto 68px;
}

.item {
  margin: 24px 0;
}

.btnHolder {
  display: flex;
  margin-top: 32px;
  justify-content: flex-start;
}

.opener {
  margin-top: 32px;
}

.counterText {
  min-height: 140px;
}

.notification {
  background: #70717C;
  padding: 16px 18px;
  color: #ECEEF2;
  font-size: 14px;
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  position: fixed;
  left: 20px;
  bottom: 20px;
}

.closeBtn {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 20px;
}

.header {
  height: 48px;
  background: #F7F8FA;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.04);
  width: 100%;
}

.logo {
  margin-top: 15px;
  margin-left: 32px;
}

.btn {
  width: 131px;
}
