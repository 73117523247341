.container {
  width: 100%;
  max-width: 236px;
  display: flex;
  min-height: 100vh;
  background: #ffffff;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  padding: 8px;
  flex-direction: column;
  box-sizing: border-box;
  margin: 10px 0 0 10px;
}

.logout {
  border: none;
  background: none;
  cursor: pointer;
  font-family: "MullerRegular", serif;
  color: #888c97;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 14px;
  height: 48px;
  border-radius: 8px;
  &:hover {
    background: #eceef2;
    color: #131416;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.listItem {
  display: flex;
  align-items: center;
  font-family: "MullerRegular", serif;
  height: 48px;
  cursor: pointer;
  color: #888c97;
  text-decoration: none;

  &:hover &:not([aria-disabled]) &:not(.active) {
    background: #f7f8fa;
    border-radius: 8px;
    color: #14142b;
  }
  & svg {
    margin: 0px 8px;
  }

  & span {
    position: relative;
    top: 3px;
    left: -1px;
  }
}

.active {
  background: #eceef2 !important;
  border-radius: 8px;
  color: #14142b;
}

.disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}

.divider {
  background: #f7f8fa;
  width: 100%;
  height: 1px;
  margin: 17px 0;
}

.balanceInfo {
  font-family: "MullerLight", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #888c97;
  margin-left: 17px;
}

.balanceCount {
  font-size: 20px;
  color: #14142b;
  font-family: "MullerBold", serif;
  margin-left: 17px;
  margin-top: 12px;
}

.wannaPlayHolder {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  height: 48px;
  background: none;
  border: none;
  cursor: pointer;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  &:hover {
    background: #eceef2;
  }
}

.topBtn {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  transform: rotate(180deg);
}

.opened {
  transform: none;
}

.logoutIcon {
  margin-right: 10px;
}

.openedList {
  margin-top: 21px;
}

.dividerLogout {
  height: 1px;
  background: #f7f8fa;
  margin-bottom: 21px;
}

.avatar {
  background: #1c1d21;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #ffffff;
  font-family: "MullerMedium", serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.companyLabel {
  font-family: "MullerRegular", serif;
  color: #1c1d21;
  font-size: 16px;
  margin-left: 8px;
  margin-right: auto;
  white-space: nowrap;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name {
  color: #888c97;
  font-size: 14px;
  font-family: "MullerRegular", serif;
  margin-top: 8px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 21px;
  margin-left: 6px;
}

.balance {
  padding: 8px;
  padding-left: 44px;
  margin-bottom: 20px;
}

.title {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #888c97;
}

.amount {
  display: block;
  font-size: 16px;
  font-family: "MullerBold";
  margin-bottom: 8px;
}
.amountWithMinus {
  margin-left: -9px;
}

.minus {
  display: inline-flex;
  width: 9px;
}

.currency {
  display: inline-flex;
  width: 12px;
  justify-content: center;
}

.logOutText {
  margin-top: 4px;
}

.logo {
  margin-left: 10px;
}

.wannaPlayContainer {
  margin-top: auto;
  position: absolute;
  bottom: 8px;
  max-width: 220px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  height: 48px;
  margin-bottom: 4px;

  .versionText {
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-10);
  }
}
