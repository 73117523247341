.container {
  width: 670px;
  background: #f7f8fa;
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  z-index: 2;
}

.holder {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(19 20 22 / 40%);
  display: flex;
  justify-content: flex-end;
}

.closeBtn {
  position: absolute;
  top: 24px;
  left: 24px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.innerWithoutButtons {
  height: 100vh !important;
  padding-bottom: 100px;
}

.innerContainer {
  margin: 80px 12px 20px 80px;
  overflow-y: auto;
  padding-right: 12px;
  height: calc(100vh - 180px);
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border: 1px solid #e1e1e4;
    border-radius: 8px;
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c9cacf;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.submit {
  width: 142px;
  height: 60px;
}

.btnsHolder {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}

.disclaimer {
  color: #70717c;
  & > b {
    color: #1c1d21;
  }
}

.backBtn {
  margin-right: 84px;
  width: 123px;
}

.nextBtn {
  width: 131px;
  margin-left: 84px;
}

.drawer {
  & [class="ant-drawer-content-wrapper"] {
    width: 692px !important;
  }

  & [class="ant-drawer-header"] {
    border: none;
  }

  & [class="ant-drawer-title"] {
    font-family: "MullerBold", sans-serif;
  }

  & [class="ant-drawer-content"] {
    background: var(--gray-3);
  }

  & [class="ant-drawer-body"] {
    width: 692px;
    padding: 0px 40px;
    overflow-x: hidden;
  }
}
