.modalHolder {
  position: absolute;
  width: 100%;
  height: 103%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background: rgb(20 20 20 / 53%);
  z-index: 4;
}
