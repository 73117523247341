.wrapper {
  width: 264px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e1e1e4;
  padding: 6px 12px;
  box-sizing: border-box;
  display: flex;
  background: #ffffff;
}

.label {
  color: #888c97;
  font-size: 10px;
  margin-bottom: 2px;
  cursor: pointer;
}
.input {
  color: #131416;
  font-family: "MullerRegular", serif;
  font-size: 14px;
  width: 210px;
  border: none;
  background: transparent;
  outline: none;
}

.icon {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  border: none;
  background: none;
}

.controlsHolder {
  display: flex;
  flex-direction: column;
}

.calendarClassName {
  font-family: "MullerRegular", serif;
}

.holder {
  position: relative;
}

.dates {
  position: absolute;
  filter: drop-shadow(0px 14px 40px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
  z-index: 2;
  top: 45px;
  right: 0px;
}
