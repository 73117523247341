.holder {
  width: 265px;
  height: 72px;
  display: flex;
  background: #fff;
  border-radius: 8px;
  padding: 16px 16px 16px 16px;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
}

.name {
  color: #70717C;
  font-family: 'MullerRegular', serif;
  font-size: 16px;
  margin-left: 8px;
  max-width: 150px;
}

.checkbox {
  margin-left: auto;
}

.connected {
  border: 1px solid #7C55EB;
}
