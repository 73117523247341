.container {
  width: 612px;
}

// dragNdrop -->
.apiLabel {
  font-size: 16px;
  font-family: "MullerRegular", sans-serif;
  color: #70717c;
  margin-bottom: 8px;
}

.uploadHolder {
  // border: 1px solid red;
}

.uploaded {
  background: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 8px;
  padding: 10px 20px 10px 10px;
  display: inline-flex;
  align-items: center;
  // margin-bottom: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.uploadedforConfirm {
  background: #ffffff;
  // padding: 10px 20px 10px 10px;
  display: inline-flex;
  align-items: center;
  // margin-bottom: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.textUploaded {
  color: #1c1d21;
  margin-left: 10px;
  margin-right: 8px;
}

.confirmLink {
  margin: 0 0 0 8px;
  color: #7145ec;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}
// dragNdrop <--

.confirmBlock {
  background: #fff;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 3px;

    .cellleft {
      min-height: 56px;
      padding: 16px 0 0 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #686f88;
    }
  }
}
