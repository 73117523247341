.container {
  background: #F7F8FA;
  display: flex;
  height: 100vh;
  padding: 30px;
}

.main {
  background: #FFFFFF;
  border-radius: 8px;
  width: 100%;
  max-width: 558px;
  height: 416px;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'MullerRegular', serif;
  text-align: center;
  letter-spacing: 0.2px;
  color: #70717C;
}

.holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%
}
