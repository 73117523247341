.paginatorHolder {
  display: flex;
  align-items: baseline;
}

.text {
  color: #888C97;
  font-family: 'MullerRegular', serif;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.rowsText {
  margin-right: 8px;
}

.rowsTextCount {
  margin-left: 32px
}

.pagContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.inactivePage {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 14px;
  color: #838998;
  cursor: pointer;
  padding-top: 4px;
}

.arrow {
  margin: 0 15px;
  cursor: pointer;
  position: relative;
  top: 1px;
  outline: none;
}

.page {
  width: 24px;
  background: #FFFFFF;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 14px;
  color: #838998;
  cursor: pointer;
  padding-top: 4px;
}

.holder {
  display: flex;
}

.selectHolder {
  width: 51px;
}
