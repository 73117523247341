.whiteBlock {
  padding: 36px 32px 32px;
  margin-top: 28px;
  background: #ffffff;
  border-radius: 8px;
}

.subTitle {
}

.halfField {
  width: calc(50% - 16px);
}

.textField {
  margin-bottom: 32px;
}

.form {
  display: flex;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.labelCheckbox {
  margin-right: 32px;
}

.container {
  width: 612px;
}

.halfInput {
  width: calc(50% - 16px);
}

.post {
  display: flex;
  justify-content: space-between;
}

.country {
  display: flex;
}

.countryName {
  margin-left: 10px;
}

.selectHolder {
  margin-bottom: 32px;
}
