.uploaded {
  background: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 8px;
  padding: 10px 20px 10px 10px;
  display: inline-flex;
  align-items: center;
  // margin-bottom: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.textUploaded {
  color: #1c1d21;
  margin-left: 10px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
