.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  padding-right: 12px;
}

.container {
  width: 953px !important;
  display: flex;

  & [class="ant-modal-header"] {
    background: var(--gray-3);
  }

  & [class="ant-modal-body"] {
    background: var(--gray-3);
  }

  & [class="ant-modal-footer"] {
    background: var(--gray-3);
    height: 88px;
  }

  & [class="ant-modal-content"] {
    width: 1000px;
  }
}

.infoRow {
  display: flex;
  flex-wrap: wrap;
}

.infoBlock {
  width: 100%;
  max-width: 451px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.infoTitle {
  font-size: 16px;
  font-family: "MullerBold", serif;
  margin-bottom: 16px;
  line-height: 1;
}

.card {
  background: #ffffff;
  border-radius: 8px;
  height: 100%;
}

.cardRow {
  min-height: 56px;
  display: flex;
}

.leftCell {
  padding: 16px 0px 16px 16px;
  width: 48%;
  color: var(--gray-10);
}

.rightCell {
  padding: 18px 18px 18px 0px;
  width: 52%;
  color: var(--gray-13);
}

.backBtn {
  margin-right: 16px;
}

.btnsHolder {
  display: flex;
  justify-content: space-between;
}
