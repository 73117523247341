.paginator {
  display: flex;
  align-items: center;
}

.active {
  width: 8px;
  height: 8px;
  background: url(../../assets/active.png);
}

.inactive {
  width: 8px;
  height: 8px;
  background: url(../../assets/inactive.png);
}

.paginatorHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backBtn {
  margin-right: 84px;
  width: 123px;
}

.nextBtn {
  width: 131px;
  margin-left: 84px;
}

.dotsHolder {
  width: 128px;
  display: flex;
  justify-content: space-between;
}

// dragNdrop -->
.apiLabel {
  font-size: 16px;
  font-family: "MullerRegular", sans-serif;
  color: #70717c;
  margin-bottom: 8px;
}

.uploaded {
  background: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 8px;
  padding: 10px 20px 10px 10px;
  display: inline-flex;
  align-items: center;
  // margin-bottom: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.uploadedforConfirm {
  background: #ffffff;
  // padding: 10px 20px 10px 10px;
  display: inline-flex;
  align-items: center;
  // margin-bottom: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.textUploaded {
  color: #1c1d21;
  margin-left: 10px;
  margin-right: 8px;
}
// dragNdrop <--
