.fundingModal {
  width: 720px;
  height: 360px;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .infoBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: #fbfbfd;
    padding: 20px;

    .header {
      color: #0e9c1c;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    .text {
      color: #686f88;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.modalContainer {
  width: 820px;
  background: #ffffff;
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  height: 452px;
  padding: 40px;
}

.grayHolder {
  background: #f7f8fa;
  border-radius: 8px;
  padding: 24px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 292px;
}

.title {
  font-family: MullerMedium, serif;
  margin-top: 24px;
  font-size: 24px;
  color: #1c1d21;
  text-align: center;
  span {
    color: #22b465;
  }
}

.modalDescription {
  color: #70717c;
  font-family: MullerRegular, serif;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

.btnSendRequest {
  margin-top: 20px;
  margin-left: auto;
  width: 152px;
  height: 60px;
  font-family: MullerMedium, serif;
}

.arrow {
  position: relative;
  flex-grow: 1;
  height: 18px;
  max-width: 276px;
  margin-left: 46px;
  margin-right: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 100%;
    margin-top: -1px;
    height: 2px;
    background-color: #7c55eb;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    margin-top: -4.5px;
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #7c55eb;
    border-right: 2px solid #7c55eb;
    transform: rotate(-45deg);
  }
}

.tableSize {
  grid-template-columns: repeat(6, 1fr);
}

.emptyBody {
  grid-template-columns: repeat(1, 1fr);
  height: 120px;
}

.noDataText {
  color: #70717c;
  font-size: 14px;
}

.endFormat {
  width: 100%;
  text-align: right;
}

.endFormatBody {
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 13px;
  width: 100%;
}

.startFormat {
  width: 100%;
  margin-left: 42px;
}

.first {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-left: 42px;
}

.titleTooltip {
  margin-right: 5px;
}

.btn {
  width: 153px;
}

.btnSendRequest {
  width: 152px;
}

.btnHolder {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.available {
  font-family: "MullerBold", serif;
  font-size: 20px;
  line-height: 20px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  width: fit-content;
  margin-bottom: 8px;
  background: none;
  &:hover {
    background: #eceef2;
  }
}

.activeAvailable {
  background: #eceef2;
}

.list {
  background: #ffffff;
  border-radius: 8px;
  padding: 4px;
  position: absolute;
  width: calc(100% - 10px);
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  z-index: 3;
}

.dropDown {
  position: relative;
  width: fit-content;
}

.active {
  background: #eceef2;
  color: #131416 !important;
}

.listItem {
  color: #70717c;
  font-size: 16px;
  font-family: "MullerRegular", serif;
  height: 36px;
  cursor: pointer;
  padding-left: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  text-decoration: none;
  &:hover {
    background: #eceef2;
    color: #131416;
  }
}

.close {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
}

.opened {
  transform: rotate(180deg);
}

.downText {
  margin-right: 9px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.block {
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  border-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.blockComplete {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.blockReview {
  padding: 23px 16px;
}

.positive {
  color: #22b465;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  min-width: 914px;
  max-width: 1314px;
  margin: 0 auto;
}

.regularText {
  color: #70717c;
  font-family: "MullerRegular", serif;
  font-size: 14px;
}

.availableText {
  margin-bottom: 12px;
}

.amount {
  color: #14142b;
  font-size: 20px;
  font-family: "MullerBold", serif;
  position: relative;
  top: 2px;
  margin-left: 8px;
  margin-right: 6px;
}

.btnCreateRequest {
  width: 280px;
  font-size: 20px;
}

.availableContent {
  display: flex;
  flex-direction: column;
}

.editRequestHolder {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 23px 16px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
}

.new {
  border: 1px solid #7c55eb;
}

.pending {
  border: 1px solid #bcbdc2;
}

.rejected {
  border: 1px solid #f43154;
}

.signed {
  border: 1px solid #fef1f3;
}

.funded {
  border: 1px solid #b099f2;
}

.link {
  font-size: 14px;
  color: #14142b;
  font-family: "MullerBold", serif;
  margin-left: 8px;
}

.editBtn {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 25px;
}

.editIcon {
  margin-left: 10px;
}

.editBtnsHolder {
  display: flex;
  margin-left: auto;
}

.newText {
  display: flex;
  align-items: center;
}

.createBtn {
  width: 280px;
}

.breakDown {
  color: #5f2eea;
  font-size: 14px;
  font-family: "MullerRegular", serif;
  margin-top: 32px;
  cursor: pointer;
  border: none;
  background: none;
  margin-bottom: 20px;
}

.backDropFunds {
  background: #f7f8fa;
  padding: 20px;
}

.logoHolder {
  width: 200px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.companyName {
  color: #131416;
  font-size: 14px;
  font-family: "MullerRegular", serif;
  margin-left: 4px;
}

.modal {
  width: 820px;
  max-width: 820px;
  background: #ffffff;
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 40px;
  box-sizing: border-box;
}

.advanceTitle {
  font-family: "MullerRegular", serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #70717c;
}

.priceInput {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #14142b;
  border-radius: 8px 8px 0px 0px;
  width: 180px;
  height: 60px;
  font-size: 32px;
  position: relative;
  padding-left: 40px;
  font-family: "MullerRegular", serif;
  font-weight: normal;
  padding-top: 6px;
  box-sizing: border-box;
  border: none;
}

.priceInputSmall {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #14142b;
  border-radius: 8px 8px 0px 0px;
  width: 122px;
  height: 40px;
  font-size: 20px;
  position: relative;
  padding-left: 23px;
  font-family: "MullerRegular", serif;
  font-weight: normal;
  padding-top: 3px;
  box-sizing: border-box;
  border: none;
}

.label {
  font-size: 32px;
  left: 11px;
  position: absolute;
  font-family: "MullerRegular", serif;
  z-index: 2;
  top: 17px;
}

.mainInputHolder {
  position: relative;
  margin: 12px auto 0;
  width: 164px;
  box-sizing: border-box;
}

.smallInputHolder {
  position: relative;
  margin: 12px auto 24px;
  width: 164px;
  box-sizing: border-box;
}

.totalInfo {
  font-size: 14px;
  margin-top: 12px;
  letter-spacing: 1px;
  color: #a3a7af;
  font-family: "MullerRegular", serif;
}

.companyDetail {
  display: flex;
  align-items: center;
}

.labelSmall {
  top: 11px;
  left: 7px;
  z-index: 2;
  position: absolute;
  font-size: 20px;
  font-family: "MullerRegular", serif;
}

.sendModalTitle {
  color: #2e2c38;
  font-family: "MullerRegular", serif;
  font-size: 24px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}

.contentHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalTextTitle {
  color: #2e2c38;
  font-family: "MullerRegular", serif;
  font-size: 24px;
  margin-bottom: 0px;
}

.modalDescription {
  color: #888c97;
  font-size: 14px;
  font-family: "MullerRegular", serif;
  margin-top: 20px;
}

.staticDown {
  padding-right: 12px;
  position: relative;
  top: -1px;
  transform: rotate(180deg);
}

.activeDown {
  padding-left: 12px;
  position: relative;
  top: -1px;
}

.percentsSlider {
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  color: #a3a7af;
  font-family: "MullerRegular", serif;
  text-align-last: right;
  position: relative;
  left: 16px;
}

.emptyTitle {
  font-size: 20px;
  font-family: MullerBold, serif;
  margin-bottom: 12px;
}

.emptySubtitle {
  font-size: 14px;
}

.emptySubtitleReview {
  color: #70717c;
}

.advanceHolder {
  height: 40px;
  display: inline-flex;
  padding: 0 18px;
  background: #ffffff;
  align-items: center;
  border-radius: 8px;
  margin-right: 20px;
}

.topDashboard {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mainTitle {
  margin: 0;
}

.advanceBlock {
  display: flex;
}
