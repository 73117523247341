.header {
  display: flex;
  align-items: center;
  gap: 4px;
}

.column {
  color: var(--grey-gray-13-total-black, #090909);
  // font-variant-numeric: lining-nums tabular-nums;
  // font-family: Muller;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
