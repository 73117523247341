.centerAlign {
  display: flex;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.errorMessage {
  margin: 0 0 0 8px;

  color: #ec3e72;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.container {
  width: 929px;
  padding: 16px 24px;
  background: #f7f7fb;
  border-radius: 8px;

  .titleBlock {
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .titleEl {
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
    }
  }

  .labelBlock {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaedf4;
    background: #fff;

    .labelEl {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0px 16px;

      color: #686f88;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      // border: 1px solid red;
    }
  }

  .totalBlock {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eaedf4;
    background: #fff;

    .totalEl {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0px 16px;

      color: #090909;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .platformBlock {
    background: #fff;

    .platformRow {
      height: 56px;

      .platformEl {
        display: flex;
        padding: 0px 16px;

        color: #090909;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        // border: 1px solid blue;
      }
    }
  }

  .btsBlock {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin: 24px 0 8px 0;
  }
}

.redInputText {
  input {
    color: #ec3e72;
  }
}
