.holder {
  background: #FFFFFF;
  border: 1px solid #E1E1E4;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  padding: 3px 4px;
  height: 40px;
}

.button {
  border: none;
  border-radius: 5px;
  padding: 8px 18px;
  font-family: 'LatoRegular', serif;
  font-size: 16px;
  background: none;
  cursor: pointer;
  color: #70717C;
  line-height: 0;
}

.active {
  color: #29292C;
  background: #ECEEF2;
}
