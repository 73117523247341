@keyframes inOutData {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

.loader {
  background: #ECEEF2;
  animation: inOutData 2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
  border-radius: 4px;
  display: inline-block;
}

.round {
  border-radius: 50%;
  padding: 0!important;
}
