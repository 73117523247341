.paginator {
  display: flex;
  align-items: center;
}

.active {
  width: 8px;
  height: 8px;
  background: url(../../assets/active.png);
}

.inactive {
  width: 8px;
  height: 8px;
  background: url(../../assets/inactive.png);
}

.paginatorHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backBtn {
  margin-right: 84px;
  width: 123px;
}

.nextBtn {
  width: 131px;
  margin-left: 84px;
}

.dotsHolder {
  width: 128px;
  display: flex;
  justify-content: space-between;
}
