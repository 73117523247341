.textarea {
  padding: 22px 18px;
  background: #FFFFFF;
  border: 1px solid #E1E1E4;
  box-sizing: border-box;
  border-radius: 8px;
  resize: none;
  width: 100%;
  font-size: 16px;
  outline: none;
  font-family: 'MullerRegular', serif;
}

.withLabel {
  padding: 32px 18px 12px 18px;
}

.withCounter {
  padding-bottom: 32px!important;
}

.error {
  border: 1px solid #F43154;
  color: #F43154;
}

.label {
  color: #888C97;
  font-size: 12px;
  position: absolute;
  top: 8px;
  left: 18px;
}

.container {
  position: relative;
  width: 100%;
}

.errorNotice {
  color: #F43154;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 4px;
}

.counter {
  color: #70717C;
  font-family: 'LatoRegular', serif;
  font-size: 14px;
  position: absolute;
  bottom: 17px;
  right: 18px;
}
