.title {
  color: #131416;
  font-family: 'MullerBold', serif;
  font-size: 20px;
  margin: 0;
}

.title2 {
  color: #131416;
  font-family: 'MullerBold', serif;
  font-size: 16px;
  margin: 0;
  align-items: center;
  display: flex;
}

.text1 {
  font-family: 'MullerRegular', serif;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #70717C;
  & a {
    color: #7C55EB;
    text-decoration: none;
  }
}

.text2 {
  font-family: 'MullerRegular', serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: #70717C;
  & a {
    color: #7C55EB;
    text-decoration: none;
  }
}
