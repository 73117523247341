.card {
  width: 290px;
  max-width: 100%;
  height: 156px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}

.btn {
  width: 100px;
  height: 28px;
  color: #131416;
  font-family: 'MullerMedium', serif;
}

.btnConnected {
  width: 141px;
  height: 28px;
}

.store {
  display: flex;
  width: 100%;
  //margin-top: 12px;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.name {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 8px;
  color: #26B634;
  color: var(--green-6)
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sync {
  font-size: 12px;
  color: var(--gray-11);
  font-family: 'MullerRegular', serif;
  width: 100%;
  padding-left: 48px;
}
