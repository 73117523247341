.selected {
  background: #ECEEF2;
  border-radius: 4px;
}

.input {
  font-size: 16px;
  border: none;
  font-family: 'MullerRegular', serif;
  outline: none;
  padding: 0;
  &::selection {
    background: #E7F0F9;
  }
}

.holder {
  background: #FFFFFF;
  border: 1px solid #ECF0F5;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  padding: 0 8px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.title {
  font-family: 'MullerRegular', serif;
  color: #838998;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 36px;
  margin: 6px;
  width: calc(100% - 11px);
}

.listItem {
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background: rgba(236, 238, 242, 0.5);
    border-radius: 4px;
  }
}

.chevron {
  position: absolute;
  right: 20px;
  display: flex;
}

.list {
  background: #ffffff;
  position: absolute;
  top: 42px;
  width: 100%;
  left: 0px;
  border-radius: 8px;
  border: 1px solid #ECF0F5;
  z-index: 3
}

.rotated {
  transform: rotate(180deg);
  padding-right: 10px;
  padding-left: 0px;
}

.label {
  font-size: 10px;
  margin-bottom: 4px;
}

