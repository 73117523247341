.title {
  font-size: 20px;
  font-family: "MullerBold", serif;
  margin-bottom: 30px;
  margin-left: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 914px;
  padding: 20px;
  min-width: 914px;
  margin: 0 auto;
}

.amountData {
  padding-right: 30px;
  text-align: right;
  display: inline-block!important;
}

.tooltip {
  position: relative;
  left: 6px;
}

.dateCell {
  font-size: 14px;
  color: #888C97;
}

.amountCell {
  font-size: 14px;
  color: #888C97;
}

.statusCell {
  font-size: 14px;
  color: #888C97;
}

.tooltipHolder {
  & span {
    display: inline-flex;
  }
}

.tooltipIcon {
  margin-left: 5px;
  color: #C4C4C4;
}

.infoHolder {
  display: flex;
}

.infoList {
  display: flex;
  flex-direction: column;
}

.header {
  height: 42px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  width: 100%;
}

.table {
  height: auto;
  & [class~='ant-table-expanded-row'] [class~='ant-table-cell'] {
    padding: 0;
    background: transparent;
  }
  & [class~='ant-table-body'] {
    overflow: auto!important;
    max-height: none!important;
  }

  & [class~='ant-table-body'] td {
    vertical-align: middle!important;
    padding: 12px 16px;
  }
}

.tableMain {
  display: flex;
  flex-direction: column;
}

.mainRowHolder {
  display: flex;
  width: 25px;
  position: absolute;
  height: 25px;
  align-items: center;
  cursor: pointer;
}

.closed {
  background: url("assets/closed.png") no-repeat 97%;
}

.opened {
  background: url("assets/opened.png") no-repeat 97%;
}

.openedInfo {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  border-top: 1px solid #f2f2f2;
}

.openedData {
  max-height: 168px;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  margin-bottom: 12px;
}

.closedData {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.firstInfo {
  width: 133px;
  padding-left: 20px;
  height: 40px;
  background: #fff;
  align-items: center;
  display: flex;
  font-weight: bold;
}

.infoList {
  padding-right: 118px;
  text-align: right;
  width: 396px;
}

.infoRow {
  height: 40px;
  background: #fff;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.infoText {
  color: #70717C;
  font-size: 14px;
  margin-right: 60px;
}

.infoPay {
  width: 100px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.isOverdue {
  color: #F43154;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888C97;
}
