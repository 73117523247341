.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1314px;
  padding: 20px;
  min-width: 914px;
  margin: 0 auto;
  align-items: center;

  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 598px;

    .title {
      color: var(--grey-gray-13-total-black, #090909);

      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    .dates {
      width: 100%;
    }
  }
}
