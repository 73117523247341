@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInDown {
  animation-name: slideInDown;
  animation-duration: 0.5s;
}