.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 914px;
  padding: 20px;
  min-width: 914px;
  margin: 0 auto;
}

.arrow {
  background: url(./assets/arrow.png);
  display: inline-block;
  width: 12px;
  height: 9px;
}

.tableSize {
  grid-template-columns: repeat(1, 20% 50% 30%);
  max-height: 700px;
  overflow: auto;
  margin-bottom: -35px;
  padding-right: 12px;
  position: absolute;
  width: 100%;
  & div {
    width: 100%;
    padding: 0 16px;
  }
  & div:nth-child(3n + 3) {
    justify-content: flex-end;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border: 1px solid #e1e1e4;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c9cacf;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.headerSize {
  grid-template-columns: repeat(1, 20% 60% 20%);
  max-height: 300px;
  overflow: auto;
  width: calc(100% - 12px);
  position: relative;
  z-index: 2;
  & div {
    width: 100%;
    padding: 0 16px;
  }
  & div:last-child {
    text-align: right;
  }
}

.input {
  padding: 12px;
  margin-left: 30px;
  width: 264px;
}

.down {
  position: absolute;
  bottom: 0;
  width: calc(100% - 12px);
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tableWrapper {
  position: relative;
  max-height: calc(100vh - 178px);
  overflow: hidden;
  margin-bottom: 12px;
}

.table {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  border-collapse: collapse;
  max-height: 100%;
  display: block;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border: 1px solid #e1e1e4;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c9cacf;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  th {
    border-bottom: 1px solid #e0e0e0;
  }
}

.tbody {
  display: inline-table;
  width: 100%;
}

.thead {
  display: inline-table;
  width: 100%;
  position: sticky;
  //z-index: 1;
  top: 0;
  background: #fff;
}

.btn {
  border: none;
  cursor: pointer;
  background: url(./assets/btn.png);
  padding: 0;
  width: 40px;
  height: 40px;
}

.controls {
  width: 100%;
  gap: 20px;
  display: flex;
  padding: 16px;
  background: #fff;
}

.moreControls {
  width: 100%;
  gap: 20px;
  display: flex;
  padding: 4px 16px 16px 16px;
  background: #fff;
}

.emptyPlaceholder {
  width: 100%;
  height: 200px;
  color: #70717c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.positive {
  color: #22b465;
}

.internal.internal-default-interest {
  background-image: url(./assets/exclamation.svg);
}

.internal.internal-capitalization {
  background-image: url(./assets/infinity.svg);
}

.internal.internal-commission,
.fee,
.internal.internal-factoring-services-fee,
.fee.internal-commission,
.fee.internal-factoring-services-fee {
  background-image: url(./assets/percent.svg);
}

.outgoing.outgoing-purchase-price {
  background-image: url(./assets/arrowUpRightBlue.svg);
}

.outgoing.outgoing-balance-payable,
.outgoing.outgoing-transit {
  background-image: url(./assets/arrowUpRight.svg);
}

.incoming {
  background-image: url(./assets/arrowLeftDown.svg);
}

.conversion {
  background-image: url(./assets/reload.svg);
}

.internal.internal-principal-amount {
  background-image: url(./assets/twoArrows.svg);
}

.adjustment {
  background-image: url(./assets/pencil.svg);
}

.iconHolder {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  right: 4px;
  top: 3px;
  background-color: #eceef2;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.cell {
  height: 48px;
}

.th {
  position: sticky;
  top: 0;
}

.cellInner {
  padding-left: 16px;
  text-align: left;
}

.cellInnerLast {
  padding-right: 16px;
  text-align: right;
}

.headerText {
  color: #888c97;
  font-size: 14px;
  font-family: MullerRegular, sans-serif;
  font-weight: normal !important;
}

.conversionHolder {
  display: flex;
  align-items: flex-end;
}

.selected {
  background: #eceef2;
}
