.container {
  margin-left: 40px;
  margin-top: 80px;
  display: flex;
  // max-width: 874px;
  justify-content: space-between;
}

.containerNoDrawer {
  margin-left: 40px;
  margin-top: 80px;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
}

.mainTitle {
  margin-bottom: 24px !important;
}

.blockNoDrawer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block {
  width: 427px;
  display: flex;
  flex-direction: column;
}

.whiteBlock {
  background: #ffffff;
  box-shadow: inset 0px 1px 0px #f7f8fa;
  border-radius: 8px;
  padding: 24px 20px 20px 20px;
  // min-height: 196px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid #eaedf4;
  height: 56px;
}

.submitBtn {
  height: 40px;
}

.whiteBlockTitle {
  margin-bottom: 16px !important;
  background: url(./assets/plus.png) no-repeat 0px -5px;
  padding-left: 30px;
}

.whiteBlockTitleAdded {
  // margin-bottom: 16px !important;
  background: url(./assets/added.png) no-repeat;
  background-position: 2px -3px;
  padding-left: 30px;
  height: 21px;

  display: flex;
  justify-content: space-between;
}

.revenueCards {
  display: grid;
  grid-template-columns: repeat(1, 290px 290px);
  gap: 20px;
  margin-bottom: 28px;
}

.connectTitle {
  margin-bottom: 28px !important;
}

.blockTitle {
  margin-top: 60px !important;
}

.drawerBtn {
  border: none;
  background: none;
  position: fixed;
  right: 0;
  padding: 0;
  top: 75px;
  cursor: pointer;
  z-index: 2;
}

.icon {
  margin-right: 5px;
}

.countryHolder {
  display: flex;
  align-items: center;
}
