@keyframes inOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes inOutData {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.wrapper {
  min-height: 404px;
  background: #ffff;
  border-radius: 8px;
  margin-bottom: 40px;
  margin-top: 10px;
  padding: 26px 16px;
  position: relative;
}

.noData {
  width: 100%;
  height: 216px;
  background: url("./assets/empty.png") no-repeat center;
  background-size: 200px;
}

.controlsHolder {
  display: flex;
  margin-bottom: 40px;
}

.chartLoader {
  animation: inOut 2s linear infinite;
}

.dataLoader {
  animation: inOutData 2s ease-out infinite;
}

.holder {
  width: 100%;
  background: url(./assets/loaderChart.svg);
  height: 300px;
}

.bar1 {
  width: 24px;
  background: #eceef2;
  margin-left: 33px;
  animation: inOut 2s ease-out infinite;
  flex-shrink: 1;
  border-radius: 4px;
  height: 150px;
}

.bar2 {
  width: 24px;
  background: #eceef2;
  margin-left: 33px;
  animation: inOut 2s ease-out infinite;
  flex-shrink: 1;
  border-radius: 4px;
  height: 48px;
}

.bar3 {
  width: 24px;
  background: #eceef2;
  margin-left: 33px;
  animation: inOut 2s ease-out infinite;
  flex-shrink: 1;
  border-radius: 4px;
  height: 88px;
}
.bar4 {
  width: 24px;
  background: #eceef2;
  margin-left: 33px;
  animation: inOut 2s ease-out infinite;
  flex-shrink: 1;
  border-radius: 4px;
  height: 108px;
}

.topLoader {
  display: flex;
  background: url(./assets/table.png) 45px -14px;
  background-repeat-y: no-repeat;
  height: 172px;
  width: 1600px;
}

.sideChartsLoader {
  display: flex;
  flex-direction: column;
  width: 16px;
  outline: 5px solid white;
  background: #ffffff;
  justify-content: space-between;
}

.sideData {
  width: 16px;
  height: 9px;
  border-radius: 4px;
  background: #eceef2;
  animation: inOutData 2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
}

.chartLoaderContainer {
  width: 100%;
  height: 226px;
  overflow: hidden;
  padding-top: 20px;
}

.mainChartsLoader {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 9px;
  margin-bottom: 4px;
  position: relative;
}

.bottomLoader {
  display: flex;
  margin-left: 45px;
  margin-top: 5px;
  animation: inOutData 2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
}

.bottomData {
  width: 29px;
  height: 12px;
  border-radius: 4px;
  background: #eceef2;
  flex-shrink: 0;
  margin: 0 25.5px;
}

.chart1 {
  margin-left: 48px;
}

.chart2 {
  margin-left: 56px;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  animation: inOutData 3s linear infinite;
}

.emptyWrapper {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 226px;
  border-radius: 8px;
  margin-bottom: 20px;
  flex-direction: column;
}

.emptyText {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  color: #70717c;
}

.searchSelect {
  margin-left: 20px;
  width: 222px;
}

.currencyHolder {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
